
import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as equipeApi from '../api/equipe'

const Equipe = () => {

    const columns = [
        {
            title: 'Cargo',
            field: "cargo",
        },
    ];

    return (
        <>
            <Header title={"Listagem da Equipe"} subtitle={"Cargos da equipe que ficarão na página inicial"}>
                <i className="fas fa-coins awesome-header"></i>
            </Header>
            <Container className="page-content">
                <BigCard>
                    <Tabela
                        columns={columns}
                        fetchFunction={equipeApi.fetchItems}
                        removeFunction={equipeApi.removeItem}
                        statusFunction={equipeApi.statusItem}
                        title={'Listagem Equipe'}
                        url={'Equipe'}
                    />
                </BigCard>

            </Container>
        </>
    )
}

export default Equipe
