import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as arquivo_categoria from '../api/categoriaArquivos'

const Categoria = () => {

  const columns = [
    {
      title: "Nome da categoria",
      field: "title",
    },
  ];

  return (
    <React.Fragment>
      <Header title={"Listagem de Categorias dos arquivos"} subtitle={""}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={arquivo_categoria.fetchItems}
            removeFunction={arquivo_categoria.removeItem}
            statusFunction={arquivo_categoria.statusItem}
            title={"categoria_arquivos"}
            url={'categoria_arquivos'}
          />
        </BigCard>

      </Container>
    </React.Fragment>
  )
}

export default Categoria
