import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Container, Modal, Form } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
//import { fetchContent, removeCasino } from '../api/casinos'
import * as contentApi from '../api/content'

const Contents = () => {
  const translations = useSelector(state => state.translations.translations['system-contents'])
  const [show, setShow] = useState(false);
  const [newcontent, setNewcontent] = useState({});
  const [fupdate, setfUpdate] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNew = () => {
    contentApi.addItem(newcontent).then(res => {
      if (res.status === 201) {
        //history.push('/contents');
        setfUpdate(current => current + 1)
        handleClose()
      }
    })
  }

  const columns = [
    {
      title: translations['edit-name'],
      field: 'name',
      filtering: true,
      sorting: true,
    },
    {
      title: translations['edit-language'],
      field: 'language',
      center: true
    },
  ];

  return (
    <React.Fragment>
      <Header title={translations['title']} subtitle={translations['subtitle']}>
        <i className="far fa-file-alt awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={contentApi.fetchItems}
            removeFunction={contentApi.removeItem}
            statusFunction={contentApi.statusItem}
            title={translations['title']}
            url={'contents'}
            fupdate={fupdate}
            addFunction={handleShow}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{translations['modal-title']}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => {
            e.preventDefault()
            handleNew()
          }}>
            <Form.Group controlId={'name'}>
              <Form.Control
                type="text"
                placeholder={translations['modal-placeholder']}
                value={!newcontent.name ? '' : newcontent.name}
                onChange={e => setNewcontent({ ...newcontent, name: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNew}>
            {translations['modal-add']}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {translations['modal-close']}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default Contents
