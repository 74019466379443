import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as apoiadasApi from '../api/apoiadas'
import { useSelector } from 'react-redux'

const Apoiadas = () => {
  const translations = useSelector(state => state.translations.translations)
  const apoiadasTranslations = translations['system-apoiadas']

  const columns = [
    {
      title: `Apoiadas`,
      field: '_id',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: rowData =>
        <img
          className="noticia-img"
          src={apoiadasApi.getImgApoiada(rowData._id)}
          alt="" />
    },
    {
      title: "Nome",
      field: "title",
      sorting: false,
      center: true,
    },
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header title={"Apoiadas"} subtitle={"Lista de apoiadas"}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'apoiadas'}
            columns={columns}
            fetchFunction={apoiadasApi.fetchItems}
            removeFunction={apoiadasApi.removeItem}
            statusFunction={apoiadasApi.statusItem}
            enableSearch
          />
        </BigCard>
      </Container>
    </React.Fragment>
  )
}

export default Apoiadas
