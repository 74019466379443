import React, { useEffect, useState } from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
// import { EditPage } from '../components/EditPage'
import * as servicoApi from '../api/servicos'
import { useSelector } from 'react-redux'

const EditServico = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const servicosTranslations = translations['system-servicos']


  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'title',
      type: 'text',
      title: "Nome do serviço",
      validation: {
        required: true
      },
      options: {
        maxLength: '150'
      },
    },
    {
      name: 'description',
      type: 'text',
      title: "Pequena descrição do serviço",
    },
    {
      name: 'callout',
      type: 'tiny',
      title: "frase para chamada do serviço",
      tooltip: {
        text: "aqui você escreve um texto chamativo para a pessoa ver mais sobre o serviço" 
      }
    },
    
    {
      name: 'image',
      type: 'dragdrop',
      title: 'Imagem',
      options: {
        maxFiles: 1
      },
      validation: {
        required: true
      },
    },
    {
      name: 'url',
      type: 'text',
      title: "Link para site",
      tooltip: {
        text: "Aqui você irá colocar o link para o serviço"
      }
    }
  ]
  return (
    <EditPage
      title={edit ? "Editar serviço" : "Adicionar Serviço"}
      subtitle={edit ? "Alterando informações do serviço" : "Adicionando informações do serviço"}
      selectedData={selectedData} 
      addFunction={servicoApi.addItem}
      fetchFunction={servicoApi.fetchItem}
      editFunction={servicoApi.editItem}
      uploadFunction={servicoApi.uploadServicos}
      edit={edit}
      url='servicos'
    />
  )
}

export default EditServico
