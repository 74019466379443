import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as arquivo_categoria from '../api/categoriaArquivos'

const EditCategoriaArquivos = ({ edit }) => {

    const selectedData = [
        {
            name: '_id',
            type: 'hidden',
            title: 'Id'
        },
        {
            name: 'title',
            type: 'text',
            title: "Nome da categoria",
            validation: {
                required: true
            }
        }
    ]
    return (
        <EditPage
            selectedData={selectedData}
            addFunction={arquivo_categoria.addItem}
            fetchFunction={arquivo_categoria.fetchItem}
            editFunction={arquivo_categoria.editItem}
            edit={edit}
            url='categoria_arquivos'
        />
    )
}

export default EditCategoriaArquivos
