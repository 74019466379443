
import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as trabalhamosComApi from '../api/trabalhamosCom'

const TrabalhamosCom = () => {

    const columns = [
        {
            title: 'Imagem',
            field: "_id",
            render: rowData => <img src={trabalhamosComApi.getImagem(rowData._id)} alt="logo" />
        },
        {
            title: 'Empresa',
            field: "empresa",
        },
    ];

    return (
        <>
            <Header title={"Listagem dos Trabalhamos com"} subtitle={"Trabalhamos com que ficarão na página inicial"}>
                <i className="fas fa-coins awesome-header"></i>
            </Header>
            <Container className="page-content">
                <BigCard>
                    <Tabela
                        columns={columns}
                        fetchFunction={trabalhamosComApi.fetchItems}
                        removeFunction={trabalhamosComApi.removeItem}
                        statusFunction={trabalhamosComApi.statusItem}
                        title={'Listagem Trabalhamos com'}
                        url={'trabalhamos-com'}
                    />
                </BigCard>

            </Container>
        </>
    )
}

export default TrabalhamosCom
