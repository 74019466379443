import React from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import { MdCopyAll, MdFileDownload, MdLink } from "react-icons/md";
import * as arquivosApi from '../api/arquivos'
import { useSelector } from 'react-redux'

const Arquivo = () => {
  const translations = useSelector(state => state.translations.translations)
  const arquivosTranslations = translations['system-arquivos']

  const columns = [
    {
      title: "Nome do arquivo",
      field: "title",
      filtering: true,
      sorting: true,
    },
    {
      title: "Arquivo",
      field: "file",
      filtering: false,
      sorting: false,
      center: true,
      render: rowData => <button onClick={() => {
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "arquivos/" + rowData._id + '/file');
        alert("Link copiado para a área de transferência")
      }} className="fileDownload"><MdLink size="2em" /></button>
    }
  ];

  return (
    <>
      <Header title={"Listagem arquivos"} subtitle={""}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={arquivosApi.fetchItems}
            removeFunction={arquivosApi.removeItem}
            statusFunction={arquivosApi.statusItem}
            title={"Arquivos"}
            url={'arquivos'}
            enableSearch
            enableDatas
          />
        </BigCard>

      </Container>
    </>
  )
}

export default Arquivo
