import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as noticiasApi from '../api/noticias'
import { fetchItems } from '../api/categorias'
import { useSelector } from 'react-redux'

const Noticias = () => {
  const translations = useSelector(state => state.translations.translations)
  const noticiasTranslations = translations['system-noticias']
  const [categorias, setCategorias] = useState({})

  useEffect(() => {
    fetchItems().then(res => {
      let categoriasOptions = {}
      res.data.forEach(categoria => {
        categoriasOptions[categoria._id] = categoria.nome
      })
      setCategorias(categoriasOptions)
    })
  }, [])

  const columns = [
    {
      title: "Imagem",
      field: '_id',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: rowData =>
        <img
          className="noticia-img"
          src={
            rowData.categoria._id !== '60b7b815cb748f4d8447b8b1' ?
              noticiasApi.getImgNoticia(rowData._id) :
              (rowData.galeria && rowData.galeria.length > 0) ?
                noticiasApi.getImgGaleria(rowData._id, rowData.galeria[0]._id) :
                ""
          }
          alt="" />
    },
    {
      title: "Título notícia",
      field: "titulo",
      sorting: true
    },
    {
      title: "Categoria notícia",
      field: "categoria",
      center: true,
      sorting: categorias,
      render: rowData => rowData.categoria.nome
    }
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header title={"Listagem de Notícias"} subtitle={""}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'noticias'}
            columns={columns}
            fetchFunction={noticiasApi.fetchItems}
            removeFunction={noticiasApi.removeItem}
            statusFunction={noticiasApi.statusItem}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  )
}

export default Noticias
