import React, { useEffect, useState } from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as apoiadaApi from '../api/apoiadas'
import { useSelector } from 'react-redux'

const EditApoiada = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'title',
      type: 'text',
      title: "Nome da apoiada",
      validation: {
        required: true
      },
    },
    {
      name: 'order',
      type: 'text',
      title: "Ordem da apoiada",
      options: {
        tooltip: 'Ordem de exibição da apoiada',
      },
      validation: {
        required: true,
      }
    },
    {
      name: 'link',
      type: 'text',
      title: "Url da Apoiada",
      validation: {
        required: true
      },
    },
    {
      name: 'img',
      type: 'dragdrop',
      title: `Logo da apoiada`,
      options: {
        maxFiles: 1
      },
      validation: {
        required: true
      },
    },
  ]

  console.log(selectedData)
  return (
    <EditPage
      title={edit ? `Editar apoiada` : `Adicionar apoiada`}
      subtitle={`Logos das apoiadas demonstradas no site`}
      selectedData={selectedData}
      addFunction={apoiadaApi.addItem}
      fetchFunction={apoiadaApi.fetchItem}
      editFunction={apoiadaApi.editItem}
      uploadFunction={apoiadaApi.uploadApoiada}
      edit={edit}
      url='apoiadas'
    />
  )
}

export default EditApoiada
