import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as redesSociaisApi from '../api/redesSociais'

const EditRedesSociais = ({ edit }) => {

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'rede_social',
      type: 'text',
      title: 'Nome da Rede',
      validation: {
        required: true
      }
    },
    {
      name: 'imagem',
      type: 'dragdrop',
      title: 'Logo da Rede',
    },
    {
      name: 'url_midia',
      title: 'Url da Rede',
      type: 'text'
    }
  ]
  return (
    <EditPage
      title={edit ? 'Editar Rede Social' : 'Adicionar Rede Social'}
      selectedData={selectedData}
      addFunction={redesSociaisApi.addItem}
      fetchFunction={redesSociaisApi.fetchItem}
      editFunction={redesSociaisApi.editItem}
      uploadFunction={redesSociaisApi.uploadRedesSociais}
      edit={edit}
      url='redes-sociais' />
  )
}

export default EditRedesSociais
