import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigitalfloripa/ed-cms/components'
import * as servicosApi from '../api/servicos'
import { useSelector } from 'react-redux'

const Servicos = () => {
  const translations = useSelector(state => state.translations.translations)

  const columns = [
    {
      title: "Imagem",
      field: '_id',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: rowData =>
        <img
          className="noticia-img"
          src={servicosApi.getFileServicos(rowData._id)}
          alt="" />
    },
    {
      title: "Nome",
      field: "title",
      sorting: true
    },
    {
      title: "Chamada",
      field: "callout",
    },
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header title={"Servicos"} subtitle={"Lista de servicos"}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'servicos'}
            columns={columns}
            fetchFunction={servicosApi.fetchItems}
            removeFunction={servicosApi.removeItem}
            statusFunction={servicosApi.statusItem}
            enableSearch
          />
        </BigCard>
      </Container>
    </React.Fragment>
  )
}

export default Servicos
