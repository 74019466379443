import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as categoriaApi from '../api/categorias'
import { useSelector } from 'react-redux'

const EditCategoria = ({ edit }) => {
    const translations = useSelector(state => state.translations.translations)
    const categoriasTranslations = translations['system-categorias']

    const selectedData = [
        {
            name: '_id',
            type: 'hidden',
            title: 'Id'
        },
        {
            name: 'nome',
            type: 'text',
            title: 'Nome da categoria',
            validation: {
                required: true
            }
        }
    ]
    return (
        <EditPage
            selectedData={selectedData}
            addFunction={categoriaApi.addItem}
            fetchFunction={categoriaApi.fetchItem}
            editFunction={categoriaApi.editItem}
            edit={edit}
            url='categorias'
        />
    )
}

export default EditCategoria
