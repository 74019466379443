import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as equipeApi from '../api/equipe'

const EditEquipe = ({ edit }) => {

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'cargo',
      type: 'text',
      title: 'Nome do Cargo',
      validation: {
        required: true
      }
    },
    {
      name: 'body',
      title: 'Descrição do cargo',
      type: 'tiny'
    }
  ]
  return (
    <EditPage
      title={edit ? 'Editar Cargo' : 'Adicionar Rede Social'}
      selectedData={selectedData}
      addFunction={equipeApi.addItem}
      fetchFunction={equipeApi.fetchItem}
      editFunction={equipeApi.editItem}
      edit={edit}
      url='equipe' />
  )
}

export default EditEquipe
