import React from 'react'
import { EditPage } from '@equipedigitalfloripa/ed-cms/components'
import * as trabalhamosComApi from '../api/trabalhamosCom'

const EditTrabalhamosCom = ({ edit }) => {

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'empresa',
      type: 'text',
      title: 'Nome da empresa',
      validation: {
        required: true
      }
    },
    {
      name: 'imagem',
      type: 'dragdrop',
      title: 'Logo da empresa',
    },
  ]
  return (
    <EditPage
      title={edit ? 'Editar Trabalhamos com' : 'Adicionar Trabalhamos com'}
      selectedData={selectedData}
      addFunction={trabalhamosComApi.addItem}
      fetchFunction={trabalhamosComApi.fetchItem}
      editFunction={trabalhamosComApi.editItem}
      uploadFunction={trabalhamosComApi.uploadTrabalhamosCom}
      edit={edit}
      url='trabalhamos-com' />
  )
}

export default EditTrabalhamosCom
