import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import { MainLayout } from '@equipedigitalfloripa/ed-cms/layouts'

import IndexPage from '../pages/Index'
import NotFoundPage from '../pages/NotFound'

import ContentsPage from '../pages/Contents'
import EditContentPage from '../pages/EditContent'

import NoticiasPage from '../pages/Noticias'
import EditNoticiasPage from '../pages/EditNoticia'

import ArquivosPage from '../pages/Arquivos'
import EditArquivosPage from '../pages/EditArquivo'

import SliderPage from '../pages/Slider'
import EditSliderPage from '../pages/EditSlider'

import CategoriasPage from '../pages/Categorias'
import EditCategoriasPage from '../pages/EditCategorias'

import PagesPage from '../pages/Pages'
import EditPagesPage from '../pages/EditPages'

import ClientesPage from '../pages/Clientes'
import EditClientesPage from '../pages/EditClientes'

import DepoimentosPage from '../pages/Depoimentos'
import EditDepoimentosPage from '../pages/EditDepoimentos'

import TrabalhamosComPage from '../pages/TrabalhamosCom'
import EditTrabalhamosComPage from '../pages/EditTrabalhamosCom'

import RedesSociaisPage from '../pages/RedesSociais'
import EditRedesSociaisPage from '../pages/EditRedesSociais'

import EquipePage from '../pages/Equipe'
import EditEquipePage from '../pages/EditEquipe'

import CategoriaArquivos from '../pages/ArquivoCategorias'
import EditCategoriaArquivos from '../pages/EditCategoriasArquivos'

import ApoiadaPage from '../pages/Apoiadas'
import EditApoiadaPage from '../pages/EditApoiada'


import ServicosPage from '../pages/Servicos'
import EditServicosPage from '../pages/EditServicos'

import {
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  Contatos,
  ViewContatosPage
} from '@equipedigitalfloripa/ed-cms/pages'

import { startSetLang, startFetchTranslations } from '@equipedigitalfloripa/ed-cms/actions'

import { useSelector } from 'react-redux'

import '@equipedigitalfloripa/ed-cms/layouts/main.css'
import '@equipedigitalfloripa/ed-cms/pages/main.css'
import '@equipedigitalfloripa/ed-cms/components/main.css'

const SystemRouter = () => {
  const dispatch = useDispatch()
  const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3000/'
  const full_url = url + 'metatags/'
  const full_url_config = url + 'config/'
  const full_url_socialmidia = url + 'socialmidia/'
  const { loggedIn, user } = useSelector(state => state.auth)
  const translations = useSelector(state => state.translations.translations.sidebar)

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang))
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const navigation = [
    {
      title: translations.site_news,
      link: [
        {
          link: '/noticias',
          title: translations.site_news
        },
        {
          link: '/categorias',
          title: 'categorias'
        }

      ]
    },
    {
      title: 'Arquivos',
      link: [
        {
          link: '/arquivos',
          title: 'Arquivos'
        },
        {
          link: '/categoria_arquivos',
          title: 'Categorias'
        }
      ],
    },
    {
      link: '/servicos',
      title: 'Serviços'
    },
    {
      link: '/apoiadas',
      title: 'Apoiadas'
    },
    {
      link: '/contents',
      title: translations.site_simple_content
    },
  ]
  return (loggedIn ?
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />

          <Route
            path="/edit/pages/:id"
            render={(props) => (
              <EditPagesPage {...props} edit={true} />
            )}
          />
          <Route path="/add/pages" component={EditPagesPage} />
          <Route path="/pages" component={PagesPage} />

          <Route
            path="/edit/noticias/:id"
            render={(props) => (
              <EditNoticiasPage {...props} edit={true} />
            )}
          />
          <Route path="/add/noticias" component={EditNoticiasPage} />
          <Route path="/noticias" component={NoticiasPage} />

          <Route
            path="/edit/contents/:id"
            render={(props) => (
              <EditContentPage {...props} edit={true} />
            )}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />

          <Route
            path="/edit/arquivos/:id"
            render={(props) => (
              <EditArquivosPage {...props} edit={true} />
            )}
          />
          <Route path="/add/arquivos" component={EditArquivosPage} />
          <Route path="/arquivos" component={ArquivosPage} />

          <Route
            path="/edit/categoria_arquivos/:id"
            render={(props) => (
              <EditCategoriaArquivos {...props} edit={true} />
            )}
          />
          <Route path="/add/categoria_arquivos" component={EditCategoriaArquivos} />
          <Route path="/categoria_arquivos" component={CategoriaArquivos} />

          <Route
            path="/edit/apoiadas/:id"
            render={(props) => (
              <EditApoiadaPage {...props} edit={true} />
            )}
          />
          <Route path="/add/apoiadas" component={EditApoiadaPage} />
          <Route path="/apoiadas" component={ApoiadaPage} />

          <Route
            path="/edit/servicos/:id"
            render={(props) => (
              <EditServicosPage {...props} edit={true} />
            )}
          />
          <Route path="/add/servicos" component={EditServicosPage} />
          <Route path="/servicos" component={ServicosPage} />

          <Route
            path="/edit/trabalhamos-com/:id"
            render={(props) => (
              <EditTrabalhamosComPage {...props} edit={true} />
            )}
          />
          <Route path="/add/trabalhamos-com" component={EditTrabalhamosComPage} />
          <Route path="/trabalhamos-com" component={TrabalhamosComPage} />

          <Route
            path="/edit/redes-sociais/:id"
            render={(props) => (
              <EditRedesSociaisPage {...props} edit={true} />
            )}
          />
          <Route path="/add/redes-sociais" component={EditRedesSociaisPage} />
          <Route path="/redes-sociais" component={RedesSociaisPage} />

          <Route
            path="/edit/equipe/:id"
            render={(props) => (
              <EditEquipePage {...props} edit={true} />
            )}
          />
          <Route path="/add/equipe" component={EditEquipePage} />
          <Route path="/equipe" component={EquipePage} />

          <Route
            path="/edit/slider/:id"
            render={(props) => (
              <EditSliderPage {...props} edit={true} />
            )}
          />
          <Route path="/add/slider" component={EditSliderPage} />
          <Route path="/slider" component={SliderPage} />

          <Route
            path="/edit/categorias/:id"
            render={(props) => (
              <EditCategoriasPage {...props} edit={true} />
            )}
          />
          <Route path="/add/categorias" component={EditCategoriasPage} />
          <Route path="/categorias" component={CategoriasPage} />

          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id" >
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
    : <Redirect to='/login' />)
}

export default SystemRouter
